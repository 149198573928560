<template>
  <div class="account-home-view">
    <loading-component v-if="isLoading" />
    <div
      v-else
      class="account-home-view__container"
    >
      <login-options-component
        :extra-data="extraData"
        :error-message="errorMessage"
        :is-loading="isLoading"
        :ab-test="abTest"
        :is-checked="isChecked"
        @socialButtonClick="socialLogin"
        @sendEmail="sendEmail"
        @showError="showError"
      />
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { mParticleMetric } from '@/metrics';
import { CookieService } from '@/services/cookie';
import { optimizeExperimentsService } from '@/services/optimize-experiments';
import { AccountClient } from '@/services/models/accounts';
import { PermissionsClient } from '@/services/models/permissions';
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { queryParamsService } from '@/services/queryParams';
import { ROUTER_PATHS } from '@/router/paths';

export default {
    components: {
        LoadingComponent: () =>
            import(
                /* webpackChunkName: "LoadingComponent"*/ '@/components/LoadingComponent/LoadingComponent'
            ),
        LoginOptionsComponent: () =>
            import(
                /* webpackChunkName: "LoginOptionsComponent"*/ '@/components/LoginOptionsComponent/LoginOptionsComponent'
            ),
    },
    data() {
        return {
            isLoading: true,
            action: null,
            extraData: null,
            abTest: '',
            isChecked: false,
            errorMessage: '',
            errorMessages: {
                429: 'loginSocialView.error-try',
                500: 'loginSocialView.error-general',
                badEmail: 'loginSocialView.email-error',
                noCFToken: 'loginSocialView.error-nocftoken',
                none: '',
            },
        };
    },
    computed: {
        redirectTo() {
            return this.action || LOGIN_ACTIONS_CONSTANTS.ACCOUNT;
        },
    },
    beforeMount() {
        const { action, login_type, client:authorizeClient } = this.$route.query;
        this.action = action || login_type;

        this.$store.dispatch('user_info_store/axn_setShowProfile', false);

        if (this.action) {
            sessionStorage.setItem('action', this.action);
        }

        if (authorizeClient) {
            PermissionsClient.saveData(this.$route.query);
        }

        if (this.action === LOGIN_ACTIONS_CONSTANTS.AUTHORIZE && !authorizeClient) {
            const authorizeParams = PermissionsClient.getData();

            this.$router.push({
                path: ROUTER_PATHS.HOME,
                query: authorizeParams,
            });
        }
    },
    async mounted() {
        sessionStorage.setItem('referrer', document.referrer);
        this.abTest = optimizeExperimentsService.getCurrentExperiment('ab_test');
        this.extraData = this.$route.query;

        mParticleMetric.track(mParticleMetric.EVENTS.SIGN_IN_VIEW, {
            source: this.action || 'none',
            ...(this.abTest && {ab_test: this.abTest}),
        });

        if (CookieService.getCookie('refresh_token')) {
            this.goToSuccess();
        }

        this.isLoading = false;
    },
    methods: {
        goToSuccess() {
            const queryParams = queryParamsService.getAlreadyLoggedUserInfo({
                action: this.action,
                ...this.$route.query,
            });

            this.$router.push({
                path: ROUTER_PATHS.CROSSROADS,
                query: queryParams,
            });
        },
        goToEmailCodeView(email) {
            if (!CookieService.getCookie('refresh_token') && email) {
                this.$router.push({
                    name: 'Email-Code-View',
                    params: { email },
                    query: this.$route.query,
                });
            }
        },
        async sendEmail(email) {
            const cfToken = sessionStorage.getItem('cf-token');
            if (!cfToken && !location.href.includes('.dev') && !email.includes('@voicemod.net')) {
                this.errorMessage = this.errorMessages['noCFToken'];
                this.isLoading = false;
                return;
            }
            this.isLoading = true;
            try {
                await AccountClient.sendEmailVerificationCode(email);
                mParticleMetric.track(mParticleMetric.EVENTS.SIGN_IN_SELECT_CLICK, {
                    mode: 'email',
                    valid_captcha: !!cfToken,
                });
                this.goToEmailCodeView(email);

            } catch (error) {
                this.errorMessage = this.errorMessages[error.response.status] || this.errorMessages['badEmail'];
                this.isLoading = false;
                Sentry.captureMessage('Can\'t send verification email');
                Sentry.captureException(error);
            }
        },
        socialLogin(button) {
            this.isLoading = true;
            mParticleMetric.track(mParticleMetric.EVENTS.SIGN_IN_SELECT_CLICK, {
                mode: button.name.toLowerCase(),
            });
            const payload = {
                source: 'web',
                download: false,
                redirect: this.redirectTo,
                extraData: this.extraData,
                originalClient: this.extraData.client || 'voicemod',
            };
            location.href = `${button.url}&payload=${JSON.stringify(payload)}`;
        },
        showError(value) {
            this.errorMessage = this.errorMessages[value];
        },
    },
};
</script>

<style src="./AccountHomeView.scss" lang="scss"></style>