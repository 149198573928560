import { CookieService } from '@/services/cookie';

const getAllQueryParams = (queryParams) => {
    return Object.entries(queryParams).map((param) => `${param[0]}=${param[1]}`);
};

const getQueryParamsAsArraySet = (params) => {
    const givenQueryParams = getAllQueryParams(params);
    const userIdCookie = CookieService.getCookie('userId');
    return new Set([
        ...(userIdCookie ? [`userId=${userIdCookie}`] : ['']),
        ...givenQueryParams,
        ...(params.action ? [`action=${params.action}`] : ['']),
    ]);
};

const trimEmptyParams = (queryParamsArray) => Array.from(queryParamsArray).filter((param) => !!param);

const generateRouterQueryParams = (arrayParams) => {
    const objectArray =  arrayParams.map((param) => ({
        [param.split('=')[0]]: param.split('=')[1],
    }));

    return Object.assign({}, ...objectArray);
};

const getAlreadyLoggedUserInfo = (params) => {
    const queryParamsArray = getQueryParamsAsArraySet(params);
    const trimmedParams = trimEmptyParams(queryParamsArray);

    return generateRouterQueryParams(trimmedParams);
};

const queryParamsService = {
    getAllQueryParams,
    getQueryParamsAsArraySet,
    trimEmptyParams,
    getAlreadyLoggedUserInfo,
};

export {
    queryParamsService,
};